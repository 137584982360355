<template>
    <main>
        <div class="modal fade" id="changeCampusModal" data-backdrop="static" data-keyboard="false" role="dialog" aria-labelledby="changeCampusModal" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content ">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <i class="fas fa-map-marker-alt fa-fw"></i>
                            Seleccionar sede
                        </h5>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-12">
                                <label class="small mb-0 font-weight-bolder">
                                    Seleccione la sede para continuar
                                    <small class="text-danger">*</small>
                                </label>
                                <ul class="list-group">
                                    <li
                                        class="list-group-item d-flex justify-content-between align-items-center"
                                        v-for="(item,index) in campus" :key="index"
                                    >
                                        <strong class="text-capitalize">{{ item.NOMBRE_SEDE }}</strong>
                                        <button class="btn btn-icon btn-sm btn-success shadow-sm" @click="save(item.ID)">
                                            <i class="fas fa-check-double fa-fw"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-dark btn-sm shadow-sm" data-dismiss="modal" @click="logout()">
                            <i class="fas fa-sign-out-alt fa-fw"></i>
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import Swal from "sweetalert2";
import loginService from "../../../services/loginService";
import sedeService from "../../../services/sedeService";
import $ from 'jquery';
export default {
    name: "selectCampus",
    data(){
        return {
            campus : [],
            campus_id : ''
        }
    },
    async mounted() {
        this.$store.commit('SET_LAYOUT', 'index-layout');
        this.$nextTick(() => $("#changeCampusModal").modal('show'));
        const {data} = await sedeService.index();
        this.campus = data;
    },
    methods: {
        async save(campusId){
            try{
                this.LoaderSpinnerShow();
                await loginService.changeCampus({campus_id : campusId});
                this.$nextTick(() => $("#changeCampusModal").modal('hide'));
                this.LoaderSpinnerHide();
                await Swal.fire('Sede seleccionada con exito', '', 'success');
                this.$store.dispatch('setCampus', this.campus.find(x => x.ID === campusId));
                this.$router.push({ name: 'dashboard' });
            }catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Swal.fire('Ups', 'ha ocurrido un error al procesar la solicitud', 'error');
            }
        },
        async logout() {
            try {
                this.LoaderSpinnerShow();
                await this.$store.dispatch("logout");
                this.LoaderSpinnerHide();
                this.$store.commit('SET_LAYOUT', 'login-layout');
                await this.$router.push({path: '/login'});
            } catch (error) {
                this.LoaderSpinnerHide();
                console.error(error);
            }
        }
    },
}
</script>

<style scoped>

</style>
